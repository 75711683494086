import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';

import Container from '@components/Container';

const Line = styled.div(({ theme }) => ({
    height: 1,
    width: '100%',
    marginBottom: theme.spacing.xxs,
    backgroundColor: theme.colors.grey.intensity800
}));

const Headline = styled.div(({ theme }) => ({
    ...theme.typography.subtitle,
    color: theme.colors.primary.main
}));

const Paragraph = styled.div(({ theme }) => ({
    ...theme.typography.pLead,
    color: theme.colors.common || theme.colors.common.background
}));

const Illustration = styled.img(({ theme }) => ({
    objectFit: 'contain',
    maxWidth: '100px'
}));

function BenefitItem({ illustration, headline, content }) {
    const theme = useTheme();

    return (
        <Container gap={theme.spacing.xs}>
            {illustration ? <Illustration src={illustration} /> : null}
            <Container
                gap={theme.spacing.xxxs}
                direction='column'
                justifyContent='flex-start'
                alignItems='flex-start'
            >
                <Line />
                <Headline>{headline}</Headline>
                <Paragraph>{content}</Paragraph>
            </Container>
        </Container>
    );
}

export default BenefitItem;
