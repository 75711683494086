const breakpoints = [ 576, 768, 992, 1366 ];

const theme = {
    colors: {
        common: {
            background: '#FFFFFF',
            foreground: '#000000',
            backgroundAlpha: 'rgba(255,255,255,0)',
            foregroundAlpha: 'rgba(0,0,0,0)'
        },
        primary: {
            main: '#FF000C',
            light: '#ff202a',
            dark: '#e7000b',
            contrastText: '#FFFFFF'
        },
        error: {
            main: '#A51C30',
            light: '#A7333F',
            contrastText: '#FFFFFF'
        },
        warning: {
            dark: '#A99014',
            main: '#CAAC15',
            light: '#FFD569',
            contrastText: '#141414'
        },
        success: {
            dark: '#05532B',
            main: '#1E794B',
            light: '#64BC90',
            contrastText: '#FFF'
        },
        grey: {
            intensity100: '#202020',
            intensity200: '#242424',
            intensity300: '#A0A0A0',
            intensity400: '#BABABA',
            intensity800: '#F4F3EF',
            intensity900: '#f9f9f9'
        }
    },
    spacing: {
        xxxs: '4px',
        xxs: '8px',
        xs: '16px',
        s: '24px',
        m: '32px',
        l: '40px',
        xl: '62px',
        xxl: '80px',
        xxxl: '106px'
    },
    borderRadius: {
        s: '4px',
        m: '8px',
        l: '16px'
    },
    mediaQuery: {
        mobileOnly: `@media (maxWidth: ${breakpoints[0] - 1}px)`,
        largeMobileOnly: `@media (maxWidth: ${breakpoints[1] - 1}px)`,
        largeMobileUp: `@media (min-width: ${breakpoints[0]}px)`,
        tabletUp: `@media (min-width: ${breakpoints[1]}px)`,
        tabletLandscapeUp: `@media (min-width: ${breakpoints[2]}px)`,
        desktopUp: `@media (min-width: ${breakpoints[3]}px)`
    },
    typography: {
        fontFamily: "'Open Sans', sans-serif",
        h1: {
            fontSize: '48px',
            lineHeight: '150%',
            fontWeight: 800,
            fontFamily: "'Mulish', sans-serif"
        },
        h2: {
            fontSize: '32px',
            lineHeight: '150%',
            fontWeight: 800,
            fontFamily: "'Mulish', sans-serif"
        },
        h3: {
            fontSize: '28px',
            lineHeight: '150%',
            fontWeight: 800,
            fontFamily: "'Mulish', sans-serif"
        },
        h4: {
            fontSize: '24px',
            lineHeight: '150%',
            fontWeight: 800,
            fontFamily: "'Mulish', sans-serif"
        },
        h5: {
            fontSize: '20px',
            lineHeight: '150%',
            fontWeight: 800,
            fontFamily: "'Mulish', sans-serif"
        },
        h6: {
            fontSize: '18px',
            lineHeight: '150%',
            fontWeight: 500,
            fontFamily: "'Mulish', sans-serif"
        },
        subtitle: {
            fontSize: '14px',
            lineHeight: '150%',
            letterSpacing: '0.02em',
            fontWeight: 800,
            textTransform: 'uppercase',
            fontFamily: "'Mulish', sans-serif"
        },
        pLead: {
            fontSize: '18px',
            lineHeight: '160%',
            fontWeight: 500,
            fontFamily: "'Mulish', sans-serif"
        },
        p: {
            fontSize: '18px',
            lineHeight: '160%',
            fontWeight: 400
        },
        pBold: {
            fontSize: '18px',
            lineHeight: '160%',
            fontWeight: 600
        },
        pTiny: {
            fontSize: '14px',
            lineHeight: '150%',
            fontWeight: 600
        },
        button: {
            fontSize: '18px',
            lineHeight: '160%',
            fontWeight: 900,
            letterSpacing: '0.02em',
            fontFamily: "'Mulish', sans-serif"
        }
    },
    size: {
        headerHeight: '75px',
        footerHeight: '150px',
        maxContentWidth: breakpoints[3],
        mintWidth: '60px',
        mintHeight: '25px',
        assetWidth: '300px',
        secondaryMarketLogosWidth: '150px',
        assetWidthTiny: '240px',
        loadingHeight: '100px',
        loadingWidth: '100px',
        loadingBorder: '8px',
        gridItemButtonsWidth: '180px'
    },
    shadow: {
        dropShadow: '5px 5px 30px 2px rgba(64,64,64,0.5)',
        dropShadowGridButtons: '4px 4px 10px 4px #000000'
    },
    transition: {
        default: 'all 150ms cubic-bezier(0.4, 0, 0.2, 1)'
    }
};

export default theme;
