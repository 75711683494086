const ROUTES = {
    home: 'home',
    about: 'about',
    collections: 'collections',
    privacy: 'privacy',
    terms: 'terms',
    license: 'nft-license',
    notfound: 'not_found'
};

export default ROUTES;
