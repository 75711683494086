import Benefits from '@context/Home/Benefits';
import Headline from '@context/Home/Headline';
import Marketing from '@context/Home/Marketing';
import React from 'react';

function Home() {
    return (
        <>
            <Headline />
            <Benefits />
            <Marketing />
        </>
    );
}

export default Home;
