import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { useState } from 'react';

import Button from '@components/Button';
import Container from '@components/Container';
import Spotlight from '@components/Spotlight';
import RES from '@utils/resources';
import STRINGS from '@utils/strings';

const CollectionHeadline = styled.h1(({ theme }) => ({
    ...theme.typography.h1,
    color: theme.colors.tertiary,
    textAlign: 'center',
    paddingBottom: theme.spacing.xl
}));

const BackgroundColorContainer = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: theme.spacing.xxl
}));

const NewCollectionMessage = styled.h6(({ theme }) => ({
    ...theme.typography.h6,
    fontWeight: '800',
    color: theme.colors.common.background
}));

const HorizontalRule = styled.div(({ theme }) => ({
    width: '100%',
    height: '1px',
    backgroundColor: theme.colors.grey.intensity400
}));

function Collections() {
    const theme = useTheme();

    const [ showNewsletter, setShowNewsletter ] = useState(false);

    const joinStyle = {
        backgroundColor: theme.colors.primary.main,
        padding: theme.spacing.m,
        gap: theme.spacing.m,
        maxWidth: 'fit-content',
        [theme.mediaQuery.tabletUp]: {
            width: '80%',
            flexDirection: 'row'
        },
        [theme.mediaQuery.mobileOnly]: {
            textAlign: 'center'
        },
        button: {
            whiteSpace: 'nowrap',
            backgroundColor: theme.colors.common.background,
            color: theme.colors.common.foreground,
            '&:hover': {
                backgroundColor: theme.colors.common.foreground,
                color: theme.colors.common.background
            }
        }
    };

    return (
        <>
            <BackgroundColorContainer>
                <Container
                    margin={`${theme.spacing.xxl} 0`}
                    direction={'column'}
                    alignItems={'center'}
                    isFullWidth={false}
                    style={{
                        maxWidth: '62rem'
                    }}
                >
                    <CollectionHeadline>
                        {STRINGS.collections.collectionHeadline}
                    </CollectionHeadline>
                    <Spotlight
                        imgSrc={RES.collections.strangeUnusual.link}
                        title={STRINGS.collections.strangeUnusual.name}
                        description={STRINGS.collections.strangeUnusual.subtext}
                        info={STRINGS.collections.strangeUnusual.info}
                        url={RES.collections.strangeUnusual.url}
                    />
                    <HorizontalRule />
                    <Spotlight
                        imgSrc={RES.collections.nftrippy.link}
                        title={STRINGS.collections.nftrippy.name}
                        description={STRINGS.collections.nftrippy.subtext}
                        info={STRINGS.collections.nftrippy.info}
                        url={RES.collections.nftrippy.url}
                    />
                    <HorizontalRule />
                    <Spotlight
                        imgSrc={RES.collections.cryptoWriter.link}
                        title={STRINGS.collections.cryptoWriter.name}
                        description={STRINGS.collections.cryptoWriter.subtext}
                        info={STRINGS.collections.cryptoWriter.info}
                        url={RES.collections.cryptoWriter.url}
                    />
                    <HorizontalRule />
                    <Spotlight
                        imgSrc={RES.collections.skullz.link}
                        title={STRINGS.collections.skullz.name}
                        description={STRINGS.collections.skullz.subtext}
                        info={STRINGS.collections.skullz.info}
                        url={RES.collections.skullz.url}
                    />
                    <HorizontalRule />
                    <Spotlight
                        imgSrc={RES.collections.darkPinup.link}
                        title={STRINGS.collections.darkPinup.name}
                        description={STRINGS.collections.darkPinup.subtext}
                        info={STRINGS.collections.darkPinup.info}
                        url={RES.collections.darkPinup.url}
                    />
                </Container>
                <Container direction={'row'} style={joinStyle}>
                    <NewCollectionMessage>
                        {STRINGS.collections.stayTunedText}
                    </NewCollectionMessage>
                    <Button
                        onClick={() =>
                            window.open(RES.externalLinks.facingsDiscord.link, '_blank')
                        }
                    >
            Join our Discord
                    </Button>
                </Container>
            </BackgroundColorContainer>
        </>
    );
}

export default Collections;
