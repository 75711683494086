import { useEffect } from 'react';

import WithRouter from '@components/WithRouter';

function ScrollToTop({ router }) {
    const { pathname } = router.location;
    useEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: 'instant'
        });
    }, [pathname]);

    return null;
}

export default WithRouter(ScrollToTop);
