import styled from '@emotion/styled';

const Button = styled.button(({ secondary, theme, style }) => ({
    ...theme.typography.button,
    color: secondary
        ? theme.colors.grey.intensity300
        : theme.colors.primary.contrastText,
    backgroundColor: secondary ? 'transparent' : theme.colors.primary.main,
    padding: secondary ? 0 : `${theme.spacing.xs} ${theme.spacing.l}`,
    border: 'none',
    borderRadius: theme.borderRadius.s,
    opacity: 1,
    transition: theme.transition.default,
    '&:hover': {
        backgroundColor: secondary ? 'transparent' : theme.colors.primary.dark,
        color: secondary ? theme.colors.primary.dark : theme.colors.backgroundColor,
        textDecoration: secondary ? 'underline' : 'none',
        cursor: 'pointer'
    },
    '&:disabled': {
        cursor: 'not-allowed',
        opacity: '50%'
    },
    '&:disabled:hover': {
        backgroundColor: secondary ? 'transparent' : theme.colors.primary.main,
        color: secondary
            ? theme.colors.common.foreground
            : theme.colors.primary.contrastText,
        textDecoration: 'none'
    },
    ...style
}));

function StyledButton({
    children,
    primary,
    secondary,
    text,
    style = {},
    ...otherProps
}) {
    return (
        <Button secondary={secondary} style={style} {...otherProps}>
            <span>{children}</span>
        </Button>
    );
}

export default StyledButton;
