import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSnapshot } from 'valtio';

import Container from '@components/Container';
import RES from '@utils/resources';
import ROUTES from '@utils/routes';
import STRINGS from '@utils/strings';

import builtByEOSNegative from '@images/built-with-love-negative.svg';
import builtByEOS from '@images/built-with-love.svg';
import eosNegative from '@images/eos-negative.svg';
import eos from '@images/eos.svg';
import poweredByWaxNegative from '@images/powered-by-WAX-negative.svg';
import poweredByWax from '@images/powered-by-WAX.svg';

import { state } from '../globalState';

const StyledFooter = styled.div(({ theme, style }) => ({
    backgroundColor: theme.colors.common.background,
    padding: theme.spacing.xs,
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: '1366px',
    gap: theme.spacing.xxl,
    [theme.mediaQuery.tabletUp]: {
        justifyContent: 'space-evenly',
        padding: `${theme.spacing.xxl} ${theme.spacing.xl} ${theme.spacing.xl}`
    },
    ...style
}));

const FooterLink = styled(Link)(({ theme }) => ({
    cursor: 'pointer',
    marginTop: theme.spacing.s,
    userSelect: 'none',
    color: theme.colors.common.foreground,
    fill: theme.colors.common.foreground,
    textDecoration: 'none',
    [theme.mediaQuery.tabletUp]: {
        marginTop: 0
    }
}));

const TextUnderLogo = styled.h4(({ theme }) => ({
    ...theme.typography.h4,
    color: theme.colors.common.foreground,
    maxWidth: '30ch'
}));

const SocialMediaLink = styled.a(({ theme }) => ({
    cursor: 'pointer'
}));

const Row = styled.div(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing.s
}));

const CompanyName = styled.div(({ theme }) => ({
    ...theme.typography.pBold,
    color: theme.colors.common.foreground,
    marginBottom: theme.spacing.xxs
}));

const Address = styled.div(({ theme }) => ({
    ...theme.typography.pTiny,
    color: theme.colors.common.foreground,
    fontWeight: 400
}));

const Copyright = styled.p(({ theme }) => ({
    ...theme.typography.pTiny,
    color: theme.colors.common.foreground,
    fontWeight: 400,
    whiteSpace: 'nowrap'
}));

const Image = styled.img(({ theme }) => ({
    height: theme.spacing.l
}));

function Footer() {
    const theme = useTheme();
    const snap = useSnapshot(state);

    const [ showNewsletter, setShowNewsletter ] = useState(false);

    const ContainerStyle = {
        [theme.mediaQuery.mobileOnly]: {
            alignItems: 'center',
            textAlign: 'center'
        }
    };

    return (
        <StyledFooter>
            <Container
                justifyContent='space-between'
                gap={theme.spacing.xl}
                isFullWidth={false}
            >
                <Container
                    direction='column'
                    gap={theme.spacing.s}
                    alignItems='flex-start'
                    style={ContainerStyle}
                >
                    <FooterLink
                        to={`${ROUTES.home}`}
                        target='_self'
                        rel='noopener noreferrer'
                    >
                        <img
                            style={{ width: '150px' }}
                            src={snap.theme ? RES.images.logoNegative.src : RES.images.logo}
                            alt={RES.images.logoNegative.alt}
                        />
                    </FooterLink>
                    <TextUnderLogo>{STRINGS.footer.textUnderLogo}</TextUnderLogo>
                </Container>
                <Container
                    direction='column'
                    alignItems='flex-end'
                    gap={theme.spacing.s}
                    style={ContainerStyle}
                >
                    <Row>
                        <SocialMediaLink
                            href={RES.externalLinks.facingsTwitter.link}
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            <box-icon
                                type='logo'
                                name='twitter'
                                alt={RES.externalLinks.facingsTwitter.label}
                                color={theme.colors.common.foreground}
                            />
                        </SocialMediaLink>
                        <SocialMediaLink
                            href={RES.externalLinks.facingsDiscord.link}
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            <box-icon
                                type='logo'
                                name='discord-alt'
                                alt={RES.externalLinks.facingsDiscord.label}
                                color={theme.colors.common.foreground}
                            />
                        </SocialMediaLink>
                        <SocialMediaLink
                            href={RES.externalLinks.facingsYouTube.link}
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            <box-icon
                                type='logo'
                                name='youtube'
                                alt={RES.externalLinks.facingsYouTube.label}
                                color={theme.colors.common.foreground}
                            />
                        </SocialMediaLink>
                        <SocialMediaLink
                            href={RES.externalLinks.facingsGithub.link}
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            <box-icon
                                type='logo'
                                name='github'
                                alt={RES.externalLinks.facingsGithub.label}
                                color={theme.colors.common.foreground}
                            />
                        </SocialMediaLink>
                    </Row>
                    <Container
                        gap={theme.spacing.xxxs}
                        direction='column'
                        alignItems='flex-end'
                        style={ContainerStyle}
                    >
                        <CompanyName>{STRINGS.footer.companyName}</CompanyName>
                        <Address>{STRINGS.footer.companyAddressLine1}</Address>
                        <Address>
                            {STRINGS.footer.companyAddressLine3}{' '}
                            {STRINGS.footer.companyAddressLine2}
                        </Address>
                    </Container>
                </Container>
            </Container>
            <Container
                flexDirection='row'
                justifyContent='space-between'
                isFullWidth={false}
                gap={theme.spacing.m}
            >
                <Copyright>{STRINGS.footer.copyright}</Copyright>
                <Container gap={theme.spacing.xs}>
                    <FooterLink to={`${ROUTES.privacy}`}>Privacy Policy</FooterLink>
                    <FooterLink to={`${ROUTES.terms}`}>Terms of Service</FooterLink>
                    <FooterLink to={`${ROUTES.license}`}>NFT License</FooterLink>
                </Container>
                <Container
                    flexDirection='row'
                    gap={theme.spacing.m}
                    justifyContent='flex-end'
                >
                    <a
                        target='_blank'
                        href={RES.externalLinks.eosd.link}
                        rel='noreferrer'
                    >
                        <Image
                            src={snap.theme ? builtByEOSNegative : builtByEOS}
                            alt='EOSD'
                        />
                    </a>
                    <a target='_blank' href={RES.externalLinks.eos.link} rel='noreferrer'>
                        <Image
                            src={snap.theme ? eosNegative : eos}
                            alt='EOS'
                            style={{ height: '50px' }}
                        />
                    </a>
                    <a target='_blank' href={RES.externalLinks.wax.link} rel='noreferrer'>
                        <Image
                            src={snap.theme ? poweredByWaxNegative : poweredByWax}
                            alt='WAX'
                        />
                    </a>
                </Container>
            </Container>
        </StyledFooter>
    );
}

export default Footer;
