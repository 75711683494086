import Footer from '@components/Footer';
import Header from '@components/Header';
import ScrollToTop from '@components/ScrollToTop';
import Content from '@context/Content';
import { ThemeProvider } from '@emotion/react';
import styled from '@emotion/styled';
import GlobalStyles from '@styles/global';
import light from '@styles/light-theme';
import dark from '@styles/theme';
import 'boxicons';
import { BrowserRouter as Router } from 'react-router-dom';

import { useSnapshot } from 'valtio';
import { state } from './globalState';

const AppContainer = styled.div(() => ({
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column'
}));

function App() {
    const snap = useSnapshot(state);

    return (
        <ThemeProvider theme={snap.theme ? dark : light}>
            <GlobalStyles />
            <Router>
                <ScrollToTop />
                <AppContainer>
                    <Header />
                    <Content />
                    <Footer />
                </AppContainer>
            </Router>
        </ThemeProvider>
    );
}

export default App;
