import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { useSnapshot } from 'valtio';

import Button from '@components/Button';
import Container from '@components/Container';
import RES from '@utils/resources';
import STRINGS from '@utils/strings';
import { state } from '../globalState';

const HeadlineText = styled.h2(({ theme }) => ({
    ...theme.typography.h2,
    margin: 0,
    maxWidth: '40ch',
    color: theme.colors.common.foreground || theme.colors.common.background
}));

const AboveHeadline = styled.p(({ theme }) => ({
    ...theme.typography.subtitle,
    color: theme.colors.primary.main,
    margin: 0
}));

const Subtext = styled.p(({ theme }) => ({
    ...theme.typography.p,
    maxWidth: '60ch',
    marginTop: '1rem'
}));

const Image = styled.img(({ theme }) => ({
    objectFit: 'contain',
    maxWidth: '45vw',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.mediaQuery.tabletUp]: {
        maxWidth: '22vw'
    }
}));

function SubscriptionSection() {
    const theme = useTheme();
    const snap = useSnapshot(state);

    return (
        <Container
            gap={theme.spacing.s}
            isFullWidth={false}
            padding={theme.spacing.xl}
        >
            <Container
                direction='column'
                gap={theme.spacing.s}
                alignItems='flex-start'
                justifyContent='flex-start'
            >
                <Container
                    direction='column'
                    justifyContent='flex-start'
                    alignItems='flex-start'
                >
                    <AboveHeadline>{STRINGS.subscriptions.aboveHeadline}</AboveHeadline>
                    <HeadlineText>{STRINGS.subscriptions.headline}</HeadlineText>
                    <Subtext>{STRINGS.subscriptions.description}</Subtext>
                    <p />
                    <Button
                        style={{ marginTop: '2rem' }}
                        onClick={() =>
                            window.open(RES.externalLinks.facingsDiscord.link, '_blank')
                        }
                    >
            Join our Discord
                    </Button>
                </Container>
            </Container>
            <Image
                src={snap.theme ? RES.images.logoNegative.icon : RES.images.icon}
                alt={RES.images.logoNegative.alt}
            />
        </Container>
    );
}

export default SubscriptionSection;
